<template>
  <div></div>
</template>

<script>
export default {};
</script>

<style scoped>
div {
    height: 50vh;
    background-color: darkgray;
    opacity: 30%;
}
</style>
